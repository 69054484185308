.App {
    /* text-align: center; */
}

.App-logo {
    animation: App-logo-spin infinite 10s linear;
    height: 40vmin;
    pointer-events: none;
}

/*.App-header {*/
/*    background-color: #282c34;*/
/*    min-height: 100vh;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    font-size: calc(10px + 2vmin);*/
/*    color: white;*/
/*}*/

.App-link {
    color: #61dafb;
}

@font-face {
  font-family: 'AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008';
  src: url('./fonts/AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008.eot');
  src: url('./fonts/AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008.eot') format('embedded-opentype'),
       url('./fonts/AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008.woff2') format('woff2'),
       url('./fonts/AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008.woff') format('woff'),
       url('./fonts/AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008.ttf') format('truetype'),
       url('./fonts/AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008.svg#AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008') format('svg');
}




.header-page {
    background: #13c3c9; /* Old browsers */
    background: -moz-linear-gradient(left, #13c3c9 1%, #7f9bc4 45%, #ab8bc2 61%, #f470c0 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #13c3c9 1%,#7f9bc4 45%,#ab8bc2 61%,#f470c0 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #13c3c9 1%,#7f9bc4 45%,#ab8bc2 61%,#f470c0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#13c3c9', endColorstr='#f470c0',GradientType=1 ); /* IE6-9 */
    padding: 20px 0px;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
  .header-page h1 {
    color: #fff;
    font-weight: bold;
    width: 100%;
    display: inline-block;
    font-family: 'AromaEuro2021BoldBold_57db80490676849c4f3b3ed8fad0f008';
    line-height: 35px;
  }
  .header-page h1 span {
    font-weight: lighter;
    font-size: 36px;
    font-family: Monsterate;
  }
  .content-area {
    display: inline-block;
    width: 100%;
    padding: 20px 0px;
    background-color: whitesmoke;
  }
  .content-area ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    display: inline-block;
    text-align: right;
  }
  .content-area ul li {
    width: auto;
    display: inline-block;
    margin-right: 10px;
  }
  .content-area ul li a {
    color: #b0b0b0;
    text-decoration: none;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .content-area ul li .web-link {
    color: #f171c0;
  }
  .content-area .global-container{
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
  }
  .content-area .card-title {
    color: #000;
  }
  .card-text form .form-group a {
    color: #f171c0;
    float: right;
  }
  .content-area .sub-heading {
    color: #acabab;
    font-size: 18px;
  }
  .content-area .btn{
    font-size: 14px;
    margin-top:20px;
  }
  .content-area .login-form {
    width: 420px;
    margin: 100px 0px;
  }
  .content-area .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent;
    background-clip: border-box;
    /*border: 1px solid rgba(0,0,0,.125);*/
    border: none;
    border-radius: .25rem;
  }
  .content-area .sign-up{
    text-align:center;
    padding:20px 0 0;
  }
  .content-area .alert{
    margin-bottom:-30px;
    font-size: 13px;
    margin-top:20px;
  }
  .card-text form .btn.btn-primary.btn-block {
    padding: 15px 0px;
    background-color: #00c9c9;
    border: none;
    font-size: 18px;
  }
  .card-text form .form-group input {
    padding: 25px 10px;
  }
  .content-area .sign-up a {
    color: #f271c0;
  }
  .footer-content {
    background-color: #000;
    width: 100%;
    display: inline-block;
    padding: 50px 0px;
  }
  .footer-content ul {
    padding: 0px;
    margin: 0px;
    display: inline-block;
    list-style: none;
    width: 100%;
    text-align: center;
  }
  .footer-content ul li {
    width: 100%;
    display: inline-block;
  }
  .footer-content ul li a {
    width: 100%;
    display: inline-block;
    padding: 5px 0px;
    color: #fff;
    font-size: 16px;
  }

  .black-header {
    background-color: #000;
    width: 100%;
    padding: 40px 0px;
    text-align: center;
    display: inline-block;
}

.black-header .navbar-nav .nav-item a {
    color: #fff;
    text-decoration: none;
  }
  
  .black-header .navbar-nav .nav-item {
    width: auto;
    display: inline-block;
  }

  .black-header .navbar-nav {
    width: 100%;
    float: right;
    text-align: right;
    display: block;
  }
  .black-header .navbar-nav .nav-item a {
    color: #fff;
    text-decoration: none;
  }

  .search-content-area {
    width: 100%;
    display: inline-block;
    padding: 100px 0px;
    text-align: center;
    background-image: url(/banner.png);
  }
  .search-content-area .search-bar-area {
    padding: 50px 0px;
    width: 100%;
    display: inline-block;
  }

  .search-content-area .search-bar-area h1 {
    font-size: 70px;
    font-weight: 600;
  }
  .search-content-area .search-bar-area h1 .special-character {
    color: #0fc4c8;
  }
  .black-header .navbar-nav .nav-item a:hover {
    color: #ddd;
  }

  .search-content-area .search-bar-area form button {
    width: 100%;
    max-width: 300px;
    padding: 10px 0px;
    background-color: #0bc5c9;
    border: none;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    border-radius: 10px;
    font-size: 26px;
  }
  .search-content-area .search-bar-area form input {
    width: 100%;
    display: inline-block;
    margin: 50px 0px;
    max-width: 1060px;
    padding: 30px 0px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #cacaca;
    border: none;
    box-shadow: 0px 0px 10px 0px #626161;
  }
  .search-content-area .search-bar-area form {
    width: 100%;
    display: block;
  }
  .search-content-area .search-bar-area form input::placeholder {
    color: #d0cfcf;
  }

  .sub-header-search {
    background-color: #EEF0F7;
    padding: 16px 0px;
    width: 100%;
    float: left;
  }
  .sub-header-search .search-bar {
    width: 100%;
    max-width: 820px;
    margin: 0 auto;
    display: inline-block;
  }
  .sub-header-search .search-bar .form-control.mr-sm-2 {
    width: 100%;
    padding: 24px 10px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px #dfdfdf;
    max-width: 70%;
  }
  .sub-header-search .search-bar .btn {
    width: 100%;
    max-width: 28%;
    padding: 10px 0px;
    background-color: #00C9C9;
    color: #fff;
    border: none;
    font-size: 18px;
  }

  .profile-product .col-md-3 {
    float: left;
    width: auto;
    margin: 0;
  }
  .product-area {
    width: 100%;
    display: inline-block;
    padding: 30px 0px;
    height: 100%;
  }
  .product-area p .all-link {
    margin-left: 20px;
    color: #FF6CBF;
  }
  .star-rating {
    display: inline-block;
    font-size: 2em;
    padding: 0 5px;
    text-align: center;
    width: 100%;
  }
  .star-rating input {
    display:none;
  }
  .star-rating label {
    color:#ccc;
    cursor:pointer;
  }
  .star-rating :checked ~ label {
    color:#ff6cbf;
  }
  .star-rating label:hover,
  .star-rating label:hover ~ label {
    color:#ff6cbf;
  }
  .profile-product {
    width: 100%;
    display: inline-block;
    padding: 30px 0px;
  }
  .profile-product .profile-image-area {
    width: 100%;
    display: inline-block;
  }
  .profile-product .profile-image-area img {
    border-radius: 50%;
    width: 200px;
    height: 200px;
  }
  .profile-product .profile-details-area {
    width: 100%;
    background-color: #fff;
    padding: 100px 20px 30px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 10px #cecece;
    margin-top: -70px;
    float: left;
    height: 100%;
    min-height: 460px;
}
  .profile-product .profile-details-area h4 {
    margin: 0;
    width: 100%;
    font-size: 16px;
  }
  .black-header .navbar-nav .nav-item a img {
    width: auto;
    float: left;
    margin-top: 5px;
    margin-right: 10px;
}
.table-view-format .image-urls-link {
  text-align: left;
  padding: 8px;
  width: 100%;
  max-width: 260px;
  text-overflow: ellipsis;
  overflow: hidden;
}
  .product-addess-details {
    width: 100%;
    display: inline-block;
  }
  .product-addess-details .address-details {
    padding: 10px 0px;
    width: 100%;
    margin: 0px;
    display: inline-block;
    list-style: none;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  .product-addess-details .address-details li {
    width: 100%;
    display: inline-block;
    margin: 0px 2px;
    line-height: normal;
  }
  .product-addess-details .address-details li p {
    margin: 0;
    width: 100%;
    color: #60647A;
  }
  .profile-product .profile-details-area .view-more-link {
    margin-top: 10px;
    display: inline-block;
  }
  .profile-product .profile-details-area .view-more-link a {
    color: #00C9C9;
  } 

  :root {
    --rotate: 10deg;
    --color: red;
    --item-width: 50px;
    --item-height: 10px;
    --color: #13c3c9; /* Old browsers */
    --color: -moz-linear-gradient(left, #13c3c9 1%, #7f9bc4 45%, #ab8bc2 61%, #f470c0 100%); /* FF3.6-15 */
    --color: -webkit-linear-gradient(left, #13c3c9 1%,#7f9bc4 45%,#ab8bc2 61%,#f470c0 100%); /* Chrome10-25,Safari5.1-6 */
    --color: linear-gradient(to right, #13c3c9 1%,#7f9bc4 45%,#ab8bc2 61%,#f470c0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    --filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#13c3c9', endColorstr='#f470c0',GradientType=1 ); /* IE6-9 */
  }
  .loader-bg {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 99;
  }
   .loader {
     position: relative;
     width: 100px;
     height: 100px;
     border-radius: 50%;
     transform: rotate(210deg);
     padding: 20px;
     position: absolute;
     top: 40%;
     left: 50%;
     transform: translate(-50%, -50%);
  }
   .loader > * {
     display: block;
     position: absolute;
     top: 50%;
     left: 50%;
     width: var(--item-width);
     height: var(--item-height);
  }
  .loader > *:nth-of-type(1) {
     animation: background 1s ease 0.025s infinite;
     transform: rotate(0deg) translate(30px) rotate(9deg) rotate(0deg);
  }
   .loader > *:nth-of-type(2) {
     animation: background 1s ease 0.05s infinite;
     transform: rotate(9deg) translate(30px) rotate(18deg) rotate(-9deg);
  }
   .loader > *:nth-of-type(3) {
     animation: background 1s ease 0.075s infinite;
     transform: rotate(18deg) translate(30px) rotate(27deg) rotate(-18deg);
  }
   .loader > *:nth-of-type(4) {
     animation: background 1s ease 0.1s infinite;
     transform: rotate(27deg) translate(30px) rotate(36deg) rotate(-27deg);
  }
   .loader > *:nth-of-type(5) {
     animation: background 1s ease 0.125s infinite;
     transform: rotate(36deg) translate(30px) rotate(45deg) rotate(-36deg);
  }
   .loader > *:nth-of-type(6) {
     animation: background 1s ease 0.15s infinite;
     transform: rotate(45deg) translate(30px) rotate(54deg) rotate(-45deg);
  }
   .loader > *:nth-of-type(7) {
     animation: background 1s ease 0.175s infinite;
     transform: rotate(54deg) translate(30px) rotate(63deg) rotate(-54deg);
  }
   .loader > *:nth-of-type(8) {
     animation: background 1s ease 0.2s infinite;
     transform: rotate(63deg) translate(30px) rotate(72deg) rotate(-63deg);
  }
   .loader > *:nth-of-type(9) {
     animation: background 1s ease 0.225s infinite;
     transform: rotate(72deg) translate(30px) rotate(81deg) rotate(-72deg);
  }
   .loader > *:nth-of-type(10) {
     animation: background 1s ease 0.25s infinite;
     transform: rotate(81deg) translate(30px) rotate(90deg) rotate(-81deg);
  }
   .loader > *:nth-of-type(11) {
     animation: background 1s ease 0.275s infinite;
     transform: rotate(90deg) translate(30px) rotate(99deg) rotate(-90deg);
  }
   .loader > *:nth-of-type(12) {
     animation: background 1s ease 0.3s infinite;
     transform: rotate(99deg) translate(30px) rotate(108deg) rotate(-99deg);
  }
   .loader > *:nth-of-type(13) {
     animation: background 1s ease 0.325s infinite;
     transform: rotate(108deg) translate(30px) rotate(117deg) rotate(-108deg);
  }
   .loader > *:nth-of-type(14) {
     animation: background 1s ease 0.35s infinite;
     transform: rotate(117deg) translate(30px) rotate(126deg) rotate(-117deg);
  }
   .loader > *:nth-of-type(15) {
     animation: background 1s ease 0.375s infinite;
     transform: rotate(126deg) translate(30px) rotate(135deg) rotate(-126deg);
  }
   .loader > *:nth-of-type(16) {
     animation: background 1s ease 0.4s infinite;
     transform: rotate(135deg) translate(30px) rotate(144deg) rotate(-135deg);
  }
   .loader > *:nth-of-type(17) {
     animation: background 1s ease 0.425s infinite;
     transform: rotate(144deg) translate(30px) rotate(153deg) rotate(-144deg);
  }
   .loader > *:nth-of-type(18) {
     animation: background 1s ease 0.45s infinite;
     transform: rotate(153deg) translate(30px) rotate(162deg) rotate(-153deg);
  }
   .loader > *:nth-of-type(19) {
     animation: background 1s ease 0.475s infinite;
     transform: rotate(162deg) translate(30px) rotate(171deg) rotate(-162deg);
  }
   .loader > *:nth-of-type(20) {
     animation: background 1s ease 0.5s infinite;
     transform: rotate(171deg) translate(30px) rotate(180deg) rotate(-171deg);
  }
   .loader > *:nth-of-type(21) {
     animation: background 1s ease 0.525s infinite;
     transform: rotate(180deg) translate(30px) rotate(189deg) rotate(-180deg);
  }
   .loader > *:nth-of-type(22) {
     animation: background 1s ease 0.55s infinite;
     transform: rotate(189deg) translate(30px) rotate(198deg) rotate(-189deg);
  }
   .loader > *:nth-of-type(23) {
     animation: background 1s ease 0.575s infinite;
     transform: rotate(198deg) translate(30px) rotate(207deg) rotate(-198deg);
  }
   .loader > *:nth-of-type(24) {
     animation: background 1s ease 0.6s infinite;
     transform: rotate(207deg) translate(30px) rotate(216deg) rotate(-207deg);
  }
   .loader > *:nth-of-type(25) {
     animation: background 1s ease 0.625s infinite;
     transform: rotate(216deg) translate(30px) rotate(225deg) rotate(-216deg);
  }
   .loader > *:nth-of-type(26) {
     animation: background 1s ease 0.65s infinite;
     transform: rotate(225deg) translate(30px) rotate(234deg) rotate(-225deg);
  }
   .loader > *:nth-of-type(27) {
     animation: background 1s ease 0.675s infinite;
     transform: rotate(234deg) translate(30px) rotate(243deg) rotate(-234deg);
  }
   .loader > *:nth-of-type(28) {
     animation: background 1s ease 0.7s infinite;
     transform: rotate(243deg) translate(30px) rotate(252deg) rotate(-243deg);
  }
   .loader > *:nth-of-type(29) {
     animation: background 1s ease 0.725s infinite;
     transform: rotate(252deg) translate(30px) rotate(261deg) rotate(-252deg);
  }
   .loader > *:nth-of-type(30) {
     animation: background 1s ease 0.75s infinite;
     transform: rotate(261deg) translate(30px) rotate(270deg) rotate(-261deg);
  }
   .loader > *:nth-of-type(31) {
     animation: background 1s ease 0.775s infinite;
     transform: rotate(270deg) translate(30px) rotate(279deg) rotate(-270deg);
  }
   .loader > *:nth-of-type(32) {
     animation: background 1s ease 0.8s infinite;
     transform: rotate(279deg) translate(30px) rotate(288deg) rotate(-279deg);
  }
   .loader > *:nth-of-type(33) {
     animation: background 1s ease 0.825s infinite;
     transform: rotate(288deg) translate(30px) rotate(297deg) rotate(-288deg);
  }
   .loader > *:nth-of-type(34) {
     animation: background 1s ease 0.85s infinite;
     transform: rotate(297deg) translate(30px) rotate(306deg) rotate(-297deg);
  }
   .loader > *:nth-of-type(35) {
     animation: background 1s ease 0.875s infinite;
     transform: rotate(306deg) translate(30px) rotate(315deg) rotate(-306deg);
  }
   .loader > *:nth-of-type(36) {
     animation: background 1s ease 0.9s infinite;
     transform: rotate(315deg) translate(30px) rotate(324deg) rotate(-315deg);
  }
   .loader > *:nth-of-type(37) {
     animation: background 1s ease 0.925s infinite;
     transform: rotate(324deg) translate(30px) rotate(333deg) rotate(-324deg);
  }
   .loader > *:nth-of-type(38) {
     animation: background 1s ease 0.95s infinite;
     transform: rotate(333deg) translate(30px) rotate(342deg) rotate(-333deg);
  }
   .loader > *:nth-of-type(39) {
     animation: background 1s ease 0.975s infinite;
     transform: rotate(342deg) translate(30px) rotate(351deg) rotate(-342deg);
  }
   .loader > *:nth-of-type(40) {
     animation: background 1s ease 1s infinite;
     transform: rotate(351deg) translate(30px) rotate(360deg) rotate(-351deg);
  }
   .loader > div {
     transform-origin: top center;
     background: var;
  }
   @keyframes background {
     from {
       background: var(--color);
    }
     to {
       background: transparent;
    }
  }
  .loder-text {
    color: #fff;
    width: 100%;
    float: left;
    text-align: center;
    position: absolute;
    top: 46%;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .loder-text p {
    font-size: 50px;
    font-weight: bold;
  }
  .loder-text p .sky-blue-text {
    color: #00C9C9;
  }
  .loder-text p .punk-text {
    color: #FF6CBF;
  }

  .modal-dialog {
    max-width: 1170px;
    margin: 1.75rem auto;
  }
  .modal-dialog .modal-body .profile-image-area {
    width: auto;
    float: left;
  }
  .table-view-format td {
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    text-align: left;
    padding: 10px 0px;
    white-space: nowrap;
    float: left;
}
.table-view-format td:nth-child(1) {
  width: 100%;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: max-content;
  margin-right: 10px;
}
.table-view-format .image-urls-link {
  text-align: left;
  padding: 8px;
  width: 100%;
  max-width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  float: left;
}
  .modal-dialog .modal-body .profile-image-area img {
    width: 100%;
    max-width: 230px;
    float: left;
    margin: 0;
    border-radius: 50%;
}
  .profile-modal-area {
    width: 100%;
    max-width: 410px;
    display: block;
    margin: 20px auto;
    overflow: hidden;
  }
  .modal-dialog .modal-body .profile-details-area {
    margin-top: 0;
    padding: 20px;
    width: auto;
    float: left;
    margin-left: 20px;
    box-shadow: none;
  }
  .modal-dialog .modal-body .profile-details-area {
    margin-top: 0;
    padding: 0px;
    width: 65%;
    float: left;
    margin-left: 20px;
    box-shadow: none;
    text-align: left;
  }
  .modal-dialog .modal-body .profile-image-area {
    width: 30%;
    float: left;
  }
  .modal-dialog .modal-body .profile-details-area .star-rating {
    text-align: left;
  }
  .modal-dialog .modal-body .profile-details-area .star-rating .star {
    margin: 0;
  }
  .table-view-format {
    padding: 20px 0px 70px 0px;
  }
  .table-view-format table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color: #42424E;
  }
  /* .table-view-format td, th {
    text-align: left;
    padding: 8px;
  } */
  .table-view-format tr:nth-child(even) {
    background-color: #F1F2F2;
  }
  .modal-fotter-address {
    width: 100%;
    display: inline-block;
  }
  .modal-fotter-address .product-addess-details {
    width: 100%;
    max-width: 410px;
    border: none;
    display: block;
    margin: 0 auto;
  }
  .modal-fotter-address .product-addess-details .address-details {
    padding: 10px 0px;
    width: 100%;
    margin: 0px;
    display: inline-block;
    list-style: none;
    border: none;
  }
  .modal-fotter-address .product-addess-details .address-details li {
    width: 31%;
    display: inline-block;
    text-align: center;
  }

  p.searchMsg {
    /* margin-top: 20px; */
    font-size: 26px;
    color: #212529;
    text-align: center;
    font-weight: 600;
    width:100%;
    float: left;
    padding:100px 0px;

  }
  
  @media (max-width: 1080px) {
  
    .star-rating {
      font-size: 30px;
    }
    .content-area .login-form {
      margin: 0px 0px;
    }
    .search-content-area {
      padding: 0px 0px;
    }
    .search-content-area .search-bar-area h1 {
      font-size: 50px;
    }
  }
  
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
