body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


div#postcode-search {
  margin: auto;
  /* margin: 0 auto; */
  padding: 0px;
  text-align: center;
  margin: 15px;
}
h6.card-title-sec {
  text-align: center;
  margin-right: 80px;
}

h6.card-title-sec i {
  font-size: 20px;
}

h6.card-title-sec span {
  margin-left: 7px;
}

p.res-sec {
  margin-top: 20px;
  font-size: 17px;
  color: #007bff;
  text-align: center;
  font-weight: 500;
}
p.errMsg {
  margin-top: 20px;
  font-size: 22px;
  color: #212529;
  text-align: center;
  font-weight: 600;
}

.table-bordered td {
  border: 1px solid #dee2e6;
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  max-width: 100px;
}

.table th, .table td {
  vertical-align: top;
  background: #fafafa;
  padding: 25px;
  overflow: hidden;
  white-space: nowrap;
  border: solid 1px #666;
}

.table-bordered {
  width: 100%;
  max-width: 100px;
}

.text-danger {
  margin-top: 10px;
  
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease;
}

img {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 14px;
}

.loader {
  position: absolute;
  width: 100%;
  height: 100%;
}
